import React, { useState, useEffect } from 'react';
import { PageProps, Link, graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from "../components/pages/container/container"
import ContainerFluid from "../components/pages/container-fluid/container-fluid"
import "../scss/homepagestyle.scss"
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"
import BackgroundImage from 'gatsby-background-image'
import { convertToBgImage } from "gbimage-bridge"
// import MapGif from "src/images/map-animated.gif"
import FlipFlapDisplay from "src/components/FLipFlap/FlipFlapEffect"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import handGif from '../images/hand.gif'
import { IoIosMail } from "react-icons/io";

type DataProps = {
    data: IndexQueryQuery
    location: Location
}

const TermsAndConditionsPage: React.FC<PageProps<DataProps>> = ({ location }) => {

    const data = useStaticQuery(
        graphql`
      query {
        peer_logo_new: file(relativePath: { eq: "pharma-peer-new.png" }) {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF, PNG]
            )
          }
        }
      }
    `
    )

    //declare image Data
    const peer_logo_new = getImage(data.peer_logo_new)

    return (
        <>
            <Layout  >
                <Seo
                    title="Terms and Conditions"
                    description="Peer Club"
                />
                <section id="hero-section" className="pb-5">
                    <Container>
                        <div className="col-lg-12">
                            <Link to="/" className="logo-link">
                                <GatsbyImage
                                    image={peer_logo_new}
                                    alt="Logo"
                                    className="logo-hero"
                                />
                            </Link>
                            <div className="row text-center">
                            </div>
                        </div>
                        <div className="row" id="how-it-works">
                            <div className="col-lg-12 hero-right-col mt-2">
                                <h1 className="delivering-global mb-4" style={{lineHeight: '1.5'}}>Terms and Conditions for Members of The Peer Club</h1>
                                <h3 className="how-it-works" style={{ fontSize: '22px' }}>1. Introduction </h3>
                                <ul style={{listStyleType: 'none'}} className="mb-4">
                                    <li><strong>1.1.</strong> By accessing, using, or registering as a member of The Peer Club  (hereinafter referred to as "the Club"), you agree to be bound by these Terms and Conditions (hereinafter referred to as "Terms"). These Terms govern your use of the Club, including all features, services, and content provided therein.</li>
                                    <li><strong>1.2.</strong> The Club is operated by Event Strategies Limited (hereinafter referred to as "the Company"), a company registered under the laws of The Republic of Ireland with its registered address at Suite 10716, 5 Fitzwilliam Square, Dublin 2 | D02 R744.</li>
                                    <li><strong>1.3.</strong> The Company reserves the right to modify, add, or remove any part of these Terms at any time, without prior notice. It is your responsibility to review these Terms periodically to ensure you are aware of any changes. Your continued use of the Club constitutes acceptance of any changes to the Terms.</li>
                                </ul>
                                <h3 className="how-it-works" style={{ fontSize: '22px' }}>2. Membership </h3>
                                <ul style={{listStyleType: 'none'}} className="mb-4">
                                    <li><strong>2.1.</strong> To become a member of the Club, you must be at least 18 years old and complete the registration process, providing accurate and up-to-date information.</li>
                                    <li><strong>2.2.</strong> Membership is granted at the sole discretion of the Company and may be revoked at any time without prior notice or explanation.</li>
                                    <li><strong>2.3.</strong> Members are responsible for maintaining the confidentiality of their account information, including their username and password. Members must immediately notify the Company of any unauthorized use of their account or other security breaches.</li>
                                    <li><strong>2.4.</strong> Membership fees, if applicable, are non-refundable, except as required by law or in accordance with the Club's refund policy.</li>
                                </ul>
                                <h3 className="how-it-works" style={{ fontSize: '22px' }}>3. Conduct </h3>
                                <ul style={{listStyleType: 'none'}} className="mb-4">
                                    <li><strong>3.1.</strong> Members must not engage in any conduct that violates applicable laws or regulations, infringes upon the rights of others, or is otherwise disruptive, harmful, or offensive.</li>
                                    <li><strong>3.2.</strong> The Company reserves the right to suspend or terminate a member's access to the Club for any reason, including but not limited to, violation of these Terms or other policies, or if the Company believes that the member's conduct is harmful to the interests of the Club or its members.</li>
                                </ul>
                                <h3 className="how-it-works" style={{ fontSize: '22px' }}>4. Intellectual Property </h3>
                                <ul style={{listStyleType: 'none'}} className="mb-4">
                                    <li><strong>4.1.</strong> All content, including but not limited to, text, images, logos, graphics, and software, on the Club's website and associated services are the property of the Company or its licensors and are protected by copyright, trademark, and other intellectual property laws.</li>
                                    <li><strong>4.2.</strong> Members may not copy, reproduce, distribute, or create derivative works from the Club's content without the prior written consent of the Company.</li>
                                </ul>
                                <h3 className="how-it-works" style={{ fontSize: '22px' }}>5. Liability </h3>
                                <ul style={{listStyleType: 'none'}} className="mb-4">
                                    <li><strong>5.1.</strong> The Company shall not be liable for any direct, indirect, incidental, or consequential damages, including but not limited to, loss of profits, data, or goodwill, arising out of or in connection with your use of, or inability to use, the Club or its services.</li>
                                    <li><strong>5.2.</strong> The Company does not guarantee the accuracy, reliability, or completeness of any information provided on the Club's website or through its services. Members use the Club and its services at their own risk.</li>
                                </ul>
                                <h3 className="how-it-works" style={{ fontSize: '22px' }}>6. Indemnification </h3>
                                <ul style={{listStyleType: 'none'}} className="mb-4">
                                    <li><strong>6.1.</strong> Members agree to indemnify, defend, and hold harmless the Company and its affiliates, officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, or expenses, including reasonable attorneys' fees and costs, arising out of or in any way connected with your access to, use of, or alleged use of the Club, your violation of these Terms, or your infringement of any intellectual property or other right of any person or entity.</li>
                                </ul>
                                <h3 className="how-it-works" style={{ fontSize: '22px' }}>7. Governing Law and Dispute Resolution </h3>
                                <ul style={{listStyleType: 'none'}} className="mb-4">
                                    <li><strong>7.1.</strong> These Terms shall be governed by and construed in accordance with the EU laws </li>
                                 </ul>
                                <h3 className="how-it-works" style={{ fontSize: '22px' }}>8. Privacy and Data Protection </h3>
                                <ul style={{listStyleType: 'none'}} className="mb-4">
                                    <li><strong>8.2.</strong> The Company is committed to protecting the privacy and personal information of its members. The Club's Privacy Policy, which is incorporated into these Terms by reference, governs the collection, use, and disclosure of personal information.</li>
                                    <li><strong>8.3.</strong> By using the Club and its services, you consent to the collection, use, and disclosure of your personal information in accordance with the Club's Privacy Policy.</li>
                                    <li><strong>8.4.</strong> Members are responsible for keeping their personal information up to date and accurate. Members may access and update their personal information by logging into their account or contacting the Company.</li>
                                </ul>
                                <h3 className="how-it-works" style={{ fontSize: '22px' }}>9. Termination and Suspension </h3>
                                <ul style={{listStyleType: 'none'}} className="mb-4">
                                    <li><strong>9.1.</strong> The Company may, in its sole discretion, terminate or suspend a member's access to the Club and its services, with or without notice, for any reason, including but not limited to violation of these Terms, non-payment of membership fees, or if the Company believes that the member's conduct is harmful to the interests of the Club or its members.</li>
                                    <li><strong>9.2.</strong> Upon termination or suspension, all rights and privileges granted to the member under these Terms shall cease immediately. The member shall promptly destroy any Club content in their possession or control.</li>
                                    <li><strong>9.3.</strong> The Company shall not be liable for any damages or losses arising from the termination or suspension of a member's access to the Club.</li>
                                </ul>
                                <h3 className="how-it-works" style={{ fontSize: '22px' }}>10. Dispute Resolution </h3>
                                <ul style={{listStyleType: 'none'}} className="mb-4">
                                    <li><strong>10.1.</strong> In the event of any dispute arising out of or in connection with these Terms, the parties shall first attempt to resolve the dispute amicably through negotiation.</li>
                                    <li><strong>10.2.</strong> If the dispute cannot be resolved through negotiation, the parties agree to submit the dispute to binding arbitration in accordance with the rules of a recognized arbitration body in the jurisdiction of the Company. The decision of the arbitrator(s) shall be final and binding on both parties.</li>
                                </ul>
                                <h3 className="how-it-works" style={{ fontSize: '22px' }}>11. Governing Law </h3>
                                <ul style={{listStyleType: 'none'}} className="mb-4">
                                    <li><strong>11.1.</strong> These Terms and any disputes arising out of or in connection with them shall be governed by and construed in accordance with the laws of the jurisdiction in which the Company is registered, without regard to its conflict of law provisions.</li>
                                </ul>
                                <h3 className="how-it-works" style={{ fontSize: '22px' }}>12. Severability </h3>
                                <ul style={{listStyleType: 'none'}} className="mb-4">
                                    <li><strong>12.1.</strong> If any provision of these Terms is found to be invalid, illegal, or unenforceable by a court of competent jurisdiction, the remaining provisions shall remain in full force and effect and shall be construed so as to best give effect to the original intent of the parties.</li>
                                    
                                </ul>
                                <h3 className="how-it-works" style={{ fontSize: '22px' }}>13. Entire Agreement </h3>
                                <ul style={{listStyleType: 'none'}} className="mb-4">
                                    <li><strong>13.1.</strong> These Terms, together with any other policies or agreements referenced herein, constitute the entire agreement between the parties with respect to the subject matter hereof and supersede all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, with respect to the subject matter.</li>
                                </ul>
                                <h3 className="how-it-works" style={{ fontSize: '22px' }}>14. Assignment </h3>
                                <ul style={{listStyleType: 'none'}} className="mb-4">
                                    <li><strong>14.1.</strong> Members may not assign or transfer their rights or obligations under these Terms without the prior written consent of the Company. The Company may assign or transfer its rights and obligations under these Terms without restriction.</li>
                                </ul>
                                <h3 className="how-it-works" style={{ fontSize: '22px' }}>15. Waiver </h3>
                                <ul style={{listStyleType: 'none'}} className="mb-4">
                                    <li><strong>15.1.</strong> No waiver of any provision of these Terms shall be deemed a further or continuing waiver of such provision or any other provision, and the Company's failure to assert any right or provision under these Terms shall not constitute a waiver of such right or provision.</li>
                                </ul>
                                <h3 className="how-it-works" style={{ fontSize: '22px' }}>16. Contact Information </h3>
                                <ul style={{listStyleType: 'none'}} className="mb-4">
                                    <li><strong>16.1.</strong> If you have any questions or concerns regarding these Terms or the Club, please contact the Company at [Contact Email Address] or [Phone Number].</li>
                                </ul>
                                <h3 className="how-it-works" style={{ fontSize: '22px' }}>17. Third-Party Content and Services </h3>
                                <ul style={{listStyleType: 'none'}} className="mb-4">
                                    <li><strong>17.1.</strong> The Club may provide links to third-party websites, content, or services that are not owned or controlled by the Company. The Company assumes no responsibility for the content, privacy policies, or practices of any third-party websites or services.</li>
                                    <li><strong>17.2.</strong> Members acknowledge and agree that their use of any third-party websites or services is at their own risk, and the Company shall not be liable for any loss or damage arising from their use of such websites or services.</li>
                                    <li><strong>17.3.</strong> The Company encourages members to review the terms and conditions and privacy policies of any third-party websites or services they access through the Club.</li>
                                </ul>
                                <h3 className="how-it-works" style={{ fontSize: '22px' }}>18. User-Generated Content </h3>
                                <ul style={{listStyleType: 'none'}} className="mb-4">
                                    <li><strong>18.1.</strong> The Club may provide features that allow members to submit, post, or share content, including but not limited to, text, images, videos, or other materials (collectively, "User-Generated Content").</li>
                                    <li><strong>18.2.</strong> By submitting User-Generated Content, members grant the Company a non-exclusive, worldwide, royalty-free, sublicensable, and transferable license to use, reproduce, distribute, prepare derivative works of, display, and perform the User-Generated Content in connection with the Club and its services.</li>
                                    <li><strong>18.3.</strong> Members represent and warrant that they own or have the necessary licenses, rights, and permissions to use and authorize the Company to use all intellectual property rights in the User-Generated Content and that the User-Generated Content does not infringe upon any third-party rights.</li>
                                    <li><strong>18.4.</strong> The Company reserves the right, in its sole discretion, to remove or modify any User-Generated Content for any reason, including but not limited to, violation of these Terms, applicable laws, or if the Company believes that the User-Generated Content is harmful to the interests of the Club or its members.</li>
                                </ul>
                                <h3 className="how-it-works" style={{ fontSize: '22px' }}>19. Fees and Payment </h3>
                                <ul style={{listStyleType: 'none'}} className="mb-4">
                                    <li><strong>19.1.</strong> Some features and services offered by the Club may require payment of fees. If applicable, members agree to pay all fees and charges associated with their use of the Club's services.</li>
                                    <li><strong>19.2.</strong> The Company may change the fees for the Club's services at any time, with or without notice. Members are responsible for reviewing the applicable fees before using any fee-based service.</li>
                                    <li><strong>19.3.</strong> All fees are non-refundable unless otherwise specified in these Terms or in the Club's refund policy.</li>
                                    
                                </ul>
                                <h3 className="how-it-works" style={{ fontSize: '22px' }}>20. Force Majeure </h3>
                                <ul style={{listStyleType: 'none'}} className="mb-4">
                                    <li><strong>20.1.</strong> The Company shall not be liable for any failure to perform its obligations under these Terms if such failure results from circumstances beyond its reasonable control, including but not limited to, acts of God, war, terrorism, labor disputes, government actions, or natural disasters.</li>
                                    
                                </ul>
                                <h3 className="how-it-works" style={{ fontSize: '22px' }}>21. Notices </h3>
                                <ul style={{listStyleType: 'none'}} className="mb-4">
                                    <li><strong>21.1.</strong> Any notices or other communications required or permitted by these Terms shall be in writing and shall be deemed given when delivered personally, by email, or by registered or certified mail, postage prepaid, return receipt requested, to the addresses specified by the parties.</li>
                                 
                                </ul>
                                <h3 className="how-it-works" style={{ fontSize: '22px' }}>22. Amendments </h3>
                                <ul style={{listStyleType: 'none'}} className="mb-4">
                                    <li><strong>22.1.</strong> The Company reserves the right to modify, add, or remove any part of these Terms at any time, without prior notice. It is the member's responsibility to review these Terms periodically to ensure they are aware of any changes. Continued use of the Club constitutes acceptance of any changes to the Terms.</li>
                                </ul>
                                <h3 className="how-it-works" style={{ fontSize: '22px' }}>23. Confidentiality and Non-Disclosure </h3>
                                <ul style={{listStyleType: 'none'}} className="mb-4">
                                    <li><strong>23.1.</strong> All meetings, communications, and interactions between members within the Club are to be treated as strictly private. Members agree not to disclose or share any information, including but not limited to, the content of meetings, personal opinions, and member contact details, obtained within the Club to any third party without approval from their fellow Club members.</li>
                                    <li><strong>23.2.</strong> Members agree not to use any contact information or connections made within the Club for any commercial purposes, including but not limited to, canvassing, marketing, or sales, without the prior written consent of the relevant members.</li>
                                    <li><strong>23.3.</strong> Any breach of confidentiality or unauthorized use of member contact information may result in the termination of membership and possible legal action, at the sole discretion of the Company.</li>
                                    
                                </ul>
                                <h3 className="how-it-works" style={{ fontSize: '22px' }}>24. No Partnership or Agency </h3>
                                <ul style={{listStyleType: 'none'}} className="mb-4">
                                    <li><strong>24.1.</strong> Nothing in these Terms shall be construed to create a partnership, joint venture, agency, or employment relationship between the Company and any member. Members are not authorized to enter into any agreements or make any commitments on behalf of the Company.</li>
                                   
                                </ul>
                                <h3 className="how-it-works" style={{ fontSize: '22px' }}>25. Limitation of Liability </h3>
                                <ul style={{listStyleType: 'none'}} className="mb-4">
                                    <li><strong>25.1.</strong> To the fullest extent permitted by law, in no event shall the Company or its affiliates, officers, directors, employees, or agents be liable to any member for any indirect, incidental, special, consequential, or punitive damages, including but not limited to, loss of profits, data, or goodwill, arising from or related to the use of, or inability to use, the Club or its services, even if the Company has been advised of the possibility of such damages.</li>
                                    <li><strong>25.2.</strong> The Company's total liability to any member for any claim arising out of or in connection with these Terms or the use of the Club or its services shall not exceed the amount of fees paid by the member during the twelve (12) months immediately preceding the event giving rise to the claim.</li>
                                    <li><strong>25.3.</strong> The limitations of liability set forth in this section shall apply even if the essential purpose of any limited remedy in these Terms has been frustrated or has failed its essential purpose.</li>
                                   
                                </ul>

                                <h3 className="how-it-works" style={{ fontSize: '22px' }}>26. No Warranty </h3>
                                <ul style={{listStyleType: 'none'}} className="mb-4">
                                    <li><strong>26.1.</strong> The Club and its services are provided on an "as-is" and "as available" basis, without any warranties of any kind, either express or implied, including but not limited to, warranties of merchantability, fitness for a particular purpose, or non-infringement.</li>
                                    <li><strong>26.2.</strong> The Company does not warrant that the Club or its services will be uninterrupted, error-free, or free of viruses or other harmful components, nor does the Company warrant that any defects will be corrected or that the Club's content will be accurate, reliable, or complete.</li>
                                    
                                </ul>
                                <h3 className="how-it-works" style={{ fontSize: '22px' }}>27. Miscellaneous </h3>
                                <ul style={{listStyleType: 'none'}} className="mb-4">
                                    <li><strong>27.1.</strong> If any provision of these Terms is held to be invalid or unenforceable by a court of competent jurisdiction, the remaining provisions shall remain in full force and effect.</li>
                                    <li><strong>27.2.</strong> These Terms and the relationship between the members and the Company shall be governed by the laws of the jurisdiction in which the Company is registered, without regard to its conflict of law provisions.</li>
                                    <li><strong>27.3.</strong> Any failure by the Company to exercise or enforce any right or provision of these Terms shall not constitute a waiver of such right or provision.</li>
                                    <li><strong>27.4.</strong> These Terms, together with the Club's Privacy Policy and any other policies or agreements referenced herein, constitute the entire agreement between the members and the Company with respect to the Club and its services and supersede all prior or contemporaneous understandings, agreements, representations, and warranties, both written and oral, with respect to the subject matter hereof.</li>
                                </ul>
                                <h3 className="how-it-works" style={{ fontSize: '22px' }}>28. Five Pillars of Collegiality </h3>
                                <ul style={{listStyleType: 'none'}} className="mb-2">
                                    <li><strong>28.1.</strong> By registering as a member of the Club, you agree to abide by the Five Pillars of The Club  as stated below:</li>
                                </ul>
                                <ol className="mb-2" style={{paddingLeft: '40px', fontSize: '16px'}}>
                                    <li>Collegiality</li>
                                    <li>Confidentiality </li>
                                    <li>Knowledge Sharing </li>
                                    <li>Trust</li>
                                    <li>Respect</li>
                                </ol>
                                <ul style={{listStyleType: 'none'}} className="mb-4">
                                    <li><strong>28.2.</strong> Failure to abide by the Five Pillars of Collegiality may result in the suspension or termination of your membership at the sole discretion of the Company.</li>
                                </ul>
                                <h3 className="how-it-works" style={{ fontSize: '22px' }}>29. Member Representation and Warranty </h3>
                                <ul style={{listStyleType: 'none'}} className="mb-2">
                                    <li><strong>29.1.</strong> By becoming a member of the Club, you represent and warrant that:</li>
                                </ul>
                                <ol className="mb-2" style={{paddingLeft: '40px', fontSize: '16px'}}>
                                    <li>You meet the eligibility requirements as set forth in Section 2.1 of these Terms.</li>
                                    <li>You will comply with all applicable laws, regulations, and these Terms in connection with your use of the Club and its services. </li>
                                    <li>You will provide true, accurate, and up-to-date information about yourself during the registration process and promptly update your information as necessary. </li>
                                </ol>
                                <ul style={{listStyleType: 'none'}} className="mb-4">
                                    <li><strong>29.2.</strong> If the Company believes that you have breached any of the above representations or warranties, the Company reserves the right to suspend or terminate your membership without notice.</li>
                                </ul>
                                <h3 className="how-it-works" style={{ fontSize: '22px' }}>30. Changes to the Club and Services </h3>
                                <ul style={{listStyleType: 'none'}} className="mb-4">
                                    <li><strong>30.1.</strong> The Company reserves the right to modify, suspend, or discontinue, temporarily or permanently, the Club or any of its services, with or without notice, at any time and for any reason. The Company shall not be liable to any member or third party for any modification, suspension, or discontinuation of the Club or its services.</li>
                                
                                </ul>
                                <h3 className="how-it-works" style={{ fontSize: '22px' }}>31. Survival </h3>
                                <ul style={{listStyleType: 'none'}} className="mb-4">
                                    <li><strong>31.1.</strong> Any provisions of these Terms that, by their nature, should survive termination or expiration of these Terms, including but not limited to, Sections 4 (Intellectual Property), 5 (Liability), 6 (Indemnification), 22 (Confidentiality and Non-Disclosure), and 24 (Limitation of Liability), shall continue in full force and effect following the termination or expiration of these Terms.</li>
                                   
                                </ul>
                                <h3 className="how-it-works" style={{ fontSize: '22px' }}>32. Headings </h3>
                                <ul style={{listStyleType: 'none'}} className="mb-4">
                                    <li><strong>32.1.</strong> The section headings in these Terms are for convenience only and have no legal or contractual effect.</li>
                                    
                                </ul>
                                <h3 className="how-it-works" style={{ fontSize: '22px' }}>33. Language </h3>
                                <ul style={{listStyleType: 'none'}} className="mb-5">
                                    <li><strong>33.1.</strong> In the event that these Terms are translated into languages other than English, the English version shall prevail in the case of any inconsistencies or discrepancies between the translations.</li>
                                </ul>
                                <h2 className="delivering-global mb-4" style={{lineHeight: '1.5'}}>Terms and Conditions of Membership: <br />Use of Members' Data (EU Law Compliance)</h2>
                                <h3 className="how-it-works" style={{ fontSize: '22px' }}>1. Data Collection and Usage </h3>
                                <ul style={{listStyleType: 'none'}} className="mb-2">
                                    <li><strong>1.1.</strong> <strong>Purpose of Data Collection</strong>: We collect and process personal data of our members to provide and improve our services, ensure compliance with legal obligations, and enhance user experience.</li>
                                    <li><strong>1.2.</strong> <strong>Types of Data Collected</strong>: The personal data we collect includes, but is not limited to, name, email address, contact details, demographic information, and any other information voluntarily provided by members.</li>
                                    <li><strong>1.3.</strong> <strong>Data Usage</strong>: The collected data will be used for the following purposes:</li>
                                </ul>
                                <ul className="mb-4" style={{paddingLeft: '40px', fontSize: '16px'}}>
                                    <li>To manage and administer your membership.</li>
                                    <li>To provide access to and use of the club’s services. </li>
                                    <li>To communicate with you regarding updates, newsletters, and promotional offers. </li>
                                    <li>To analyze and improve our services.</li>
                                </ul>
                                <h3 className="how-it-works" style={{ fontSize: '22px' }}>2. Data Protection and Security </h3>
                                <ul style={{listStyleType: 'none'}} className="mb-4">
                                    <li><strong>2.1.</strong> <strong>Data Security</strong>: We implement appropriate technical and organizational measures to protect your personal data against unauthorized access, alteration, disclosure, or destruction.</li>
                                    <li><strong>2.2.</strong> <strong>Data Encryption</strong>: All personal data is stored securely and transmitted using encryption protocols.</li>
                                    <li><strong>2.3.</strong> <strong>Access Control</strong>: Access to personal data is restricted to authorized personnel who require this information to perform their duties.</li>
                                    
                                </ul>
                                <h3 className="how-it-works" style={{ fontSize: '22px' }}>3. Data Sharing and Disclosure </h3>
                                <ul style={{listStyleType: 'none'}} className="mb-4">
                                    <li><strong>3.1.</strong> <strong>Third-Party Processors</strong>: We may share personal data with third-party service providers who assist us in operating our services. These processors are bound by data protection agreements and are not allowed to use personal data for any other purpose.</li>
                                    <li><strong>3.2.</strong> <strong>Legal Requirements</strong>: We may disclose personal data if required to do so by law or in response to valid requests by public authorities.</li>
                                    <li><strong>3.3.</strong> <strong>Business Transfers</strong>: In the event of a merger, acquisition, or sale of assets, personal data may be transferred to the acquiring entity, provided that the recipient agrees to protect personal data in accordance with this policy.</li>
                                    
                                </ul>
                                <h3 className="how-it-works" style={{ fontSize: '22px' }}>4. Members' Rights </h3>
                                <ul style={{listStyleType: 'none'}} className="mb-4">
                                    <li><strong>4.1.</strong> <strong>Right to Access</strong>: Members have the right to request access to their personal data and obtain a copy of the information we hold about them.</li>
                                    <li><strong>4.2.</strong> <strong>Right to Rectification</strong>: Members can request the correction of inaccurate or incomplete data.</li>
                                    <li><strong>4.3.</strong> <strong>Right to Erasure</strong>: Members have the right to request the deletion of their personal data under certain conditions, such as when the data is no longer necessary for the purposes it was collected.</li>
                                    <li><strong>4.4.</strong> <strong>Right to Restrict Processing</strong>: Members can request the restriction of processing of their personal data in certain circumstances.</li>
                                    <li><strong>4.5.</strong> <strong>Right to Data Portability</strong>: Members have the right to receive their personal data in a structured, commonly used, and machine-readable format, and to transmit those data to another controller.</li>
                                    <li><strong>4.6.</strong> <strong>Right to Object</strong>: Members can object to the processing of their personal data for direct marketing purposes or on grounds relating to their particular situation.</li>
                                </ul>
                                <h3 className="how-it-works" style={{ fontSize: '22px' }}>5. Data Retention </h3>
                                <ul style={{listStyleType: 'none'}} className="mb-4">
                                    <li><strong>5.1.</strong> <strong>Retention Period</strong>: We will retain personal data only for as long as necessary to fulfill the purposes for which it was collected or to comply with legal obligations.</li>
                                    <li><strong>5.2.</strong> <strong>Anonymization</strong>: After the retention period, personal data will either be securely deleted or anonymized so that it can no longer be associated with the individual.</li>
                                </ul>
                                <h3 className="how-it-works" style={{ fontSize: '22px' }}>6. Consent and Withdrawal </h3>
                                <ul style={{listStyleType: 'none'}} className="mb-4">
                                    <li><strong>6.1.</strong> <strong>Obtaining Consent</strong>: By joining the club and providing personal data, members consent to the processing of their personal data in accordance with this policy.</li>
                                    <li><strong>6.2.</strong> <strong>Withdrawal of Consent</strong>: Members can withdraw their consent at any time by contacting us. Withdrawal of consent will not affect the lawfulness of processing based on consent before its withdrawal.</li>
                                
                                </ul>
                                <h3 className="how-it-works" style={{ fontSize: '22px' }}>7. Changes to the Terms </h3>
                                <ul style={{listStyleType: 'none'}} className="mb-4">
                                    <li><strong>7.1.</strong> <strong>Policy Updates</strong>: We reserve the right to modify these terms at any time. Members will be notified of any significant changes through appropriate means, such as email or notifications on our website.</li>
                                    <li><strong>7.2.</strong> <strong>Acceptance of Changes</strong>: Continued use of our services after the notification of changes constitutes acceptance of the updated terms.</li>
                                </ul>

                                <h3 className="how-it-works" style={{ fontSize: '22px' }}>8. Contact Information </h3>
                                <ul style={{listStyleType: 'none'}} className="mb-5">
                                    <li><strong>8.1.</strong> <strong>Data Protection Officer</strong>: For any questions or concerns regarding your personal data or these terms, please contact our Data Protection Officer at <a href="mailto:dataofficer@thepeerclub.com">dataofficer@thepeerclub.com</a></li>
                                    <li><strong>8.2.</strong> <strong>Complaints</strong>: If you believe that your data protection rights have been violated, you have the right to lodge a complaint with the relevant supervisory authority.</li>
                                   
                                </ul>
                                <h2 className="delivering-global mb-4" style={{lineHeight: '1.5'}}>Privacy Policy Clauses</h2>
                                <h3 className="how-it-works" style={{ fontSize: '22px' }}>1. Introduction </h3>
                                <ul style={{listStyleType: 'none'}} className="mb-4">
                                    <li><strong>1.1.</strong> <strong>Purpose</strong>: This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website or join our club.</li>
                                    <li><strong>1.2.</strong> <strong>Scope</strong>: This policy applies to all data collected through our website, membership forms, and any other related services.</li>
                                   
                                </ul>
                                <h3 className="how-it-works" style={{ fontSize: '22px' }}>2. Data Collection </h3>
                                <ul style={{listStyleType: 'none'}} className="mb-4">
                                    <li><strong>2.1.</strong> <strong>Information We Collect</strong>: We collect personal information that you voluntarily provide to us, such as name, email address, and contact details.</li>
                                    <li><strong>2.2.</strong> <strong>Automatic Data Collection</strong>: We automatically collect certain information when you visit our website, including IP address, browser type, and access times.</li>
                                    
                                </ul>
                                <h3 className="how-it-works" style={{ fontSize: '22px' }}>3. Use of Your Information </h3>
                                <ul style={{listStyleType: 'none'}} className="mb-2">
                                    <li><strong>3.1.</strong> <strong>Purpose of Use</strong>: We use the information we collect to:</li>
                                </ul>
                                <ul className="mb-4" style={{paddingLeft: '40px', fontSize: '16px'}}>
                                    <li>Provide, operate, and maintain our services.</li>
                                    <li>Improve, personalize, and expand our services. </li>
                                    <li>Communicate with you, including for customer service, updates, and marketing purposes.</li>
                                </ul>
                                <h3 className="how-it-works" style={{ fontSize: '22px' }}>4. Disclosure of Your Information </h3>
                                <ul style={{listStyleType: 'none'}} className="mb-4">
                                    <li><strong>4.1.</strong> <strong>Third-Party Service Providers</strong>: We may share your information with third-party service providers who perform services on our behalf.</li>
                                    <li><strong>4.2.</strong> <strong>Legal Obligations</strong>: We may disclose your information if required by law, such as to comply with a subpoena or similar legal process.</li>
                                    <li><strong>4.3.</strong> <strong>Business Transfers</strong>: If we are involved in a merger, acquisition, or sale of assets, your information may be transferred as part of that transaction.</li>
                                </ul>
                                <h3 className="how-it-works" style={{ fontSize: '22px' }}>5. Security of Your Information </h3>
                                <ul style={{listStyleType: 'none'}} className="mb-4">
                                    <li><strong>5.1.</strong> <strong>Protection Measures</strong>: We use administrative, technical, and physical security measures to help protect your personal information.</li>
                                    <li><strong>5.2.</strong> <strong>Data Breaches</strong>: In the event of a data breach, we will notify you and any applicable regulator within 72 hours of becoming aware of the breach, if legally required.</li>
                                   
                                </ul>
                                <h3 className="how-it-works" style={{ fontSize: '22px' }}>6. Your Data Protection Rights </h3>
                                <ul style={{listStyleType: 'none'}} className="mb-4">
                                    <li><strong>6.1.</strong> <strong>Access and Rectification</strong>: You have the right to access and request correction of your personal information.</li>
                                    <li><strong>6.2.</strong> <strong>Erasure and Restriction</strong>: You have the right to request the deletion or restriction of your personal information under certain conditions.</li>
                                    <li><strong>6.3.</strong> <strong>Data Portability</strong>: You have the right to request a copy of your personal information in a structured, commonly used, and machine-readable format.</li>
                                    <li><strong>6.4.</strong> <strong>Objection</strong>: You have the right to object to the processing of your personal information for direct marketing purposes.</li>
                                </ul>
                                <h3 className="how-it-works" style={{ fontSize: '22px' }}>7. Retention of Your Information </h3>
                                <ul style={{listStyleType: 'none'}} className="mb-4">
                                    <li><strong>7.1.</strong> <strong>Retention Period</strong>: We retain personal information for as long as necessary to provide our services, comply with legal obligations, resolve disputes, and enforce our agreements.</li>
                                    
                                </ul>
                                <h3 className="how-it-works" style={{ fontSize: '22px' }}>8. Consent and Withdrawal </h3>
                                <ul style={{listStyleType: 'none'}} className="mb-4">
                                    <li><strong>8.1.</strong> <strong>Consent</strong>: By using our services, you consent to the collection and use of your personal information as described in this policy.</li>
                                    <li><strong>8.2.</strong> <strong>Withdrawal of Consent</strong>: You may withdraw your consent at any time by contacting us. Withdrawal of consent does not affect the lawfulness of processing before withdrawal.</li>
                                    
                                </ul>
                                <h3 className="how-it-works" style={{ fontSize: '22px' }}>9. Changes to This Privacy Policy </h3>
                                <ul style={{listStyleType: 'none'}} className="mb-4">
                                    <li><strong>9.1.</strong> <strong>Updates</strong>: We may update this privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on our website and updating the effective date.</li>
                                    <li><strong>9.2.</strong> <strong>Acceptance of Changes</strong>: Your continued use of our services after any changes indicates your acceptance of the new privacy policy.</li>
                                </ul>
                                <h3 className="how-it-works" style={{ fontSize: '22px' }}>10. Contact Us </h3>
                                <ul style={{listStyleType: 'none'}} className="mb-4">
                                    <li><strong>8.1.</strong> <strong>Data Protection Officer</strong>: If you have any questions about this privacy policy, please contact our Data Protection Officer at <a href="mailto:dpo@thepeerclub.com">dpo@thepeerclub.com</a></li>
                                    <li><strong>8.2.</strong> <strong>Complaints</strong>: If you believe your data protection rights have been violated, you may lodge a complaint with the relevant supervisory authority.</li>
                                </ul>
                            </div>
                        </div>
                    </Container>
                </section>

            </Layout>
        </>
    )
}

export default TermsAndConditionsPage
